<ng-container *ngIf="notifications.length">
  <div class="notification"
       [ngClass]="[notification.type, (notification?.config?.textAlign || '')]"
       *ngFor="let notification of notifications">
    <div class="icon">
      <img class="pl-1"
           src="assets/images/icons/exclamation-1.svg">
    </div>
    <div class="messages-container w-100">
      <div *ngIf="notification?.content?.violations?.length"
           class="d-inline-block pl-3 pr-1 w-100">
        <button type="button"
                class="close"
                (click)="clear()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div *ngFor="let violation of notification?.content?.violations">
          {{ violation.message }}
        </div>
      </div>
      <div *ngIf="!notification?.content?.violations?.length"
           class="d-inline-block pl-3 pr-1 w-100">
        <button type="button"
                class="close"
                (click)="clear()"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        {{ notification.content?.clientMessage || notification.content }}
      </div>
    </div>
  </div>
</ng-container>

