<div *ngFor="let level of levels; let currentLevel = index;">

  <div *ngFor="let bu of level"
       class="level">
    <div *ngFor="let dot of [].constructor(currentLevel + 1)"
         class="dot"></div>
    <div class="spacer"></div>
    <div class="controls">
      <reporter-icon
        class="mr-2"
        [icon]="bu.hasChildren ? ((selected?.id === bu.id && !!children?.length) ? 'Expanded' : 'Collapsed') :
               'Leaf'"
        [cursorPointer]="bu.hasChildren"
        (click)="select(bu, currentLevel)">
      </reporter-icon>
    </div>
    <div class="cursor-pointer col"
         (click)="getBUDetails(bu)">
      <div class="d-flex align-middle justify-content-between">
        <div>
          <span *ngIf="systemClientSelected && bu.displayName?.toUpperCase() !== 'SYSTEM'">
            {{ '[' + bu.gbClientNumber + ']' }}
          </span>
          {{ bu.displayName }}
        </div>
        <button type="button"
          class="btn btn-action py-0"
          *ngIf="bu.id === buDetails?.id && (!reportingLocationOnly || bu.reportingLocation)"
          [class.cursor-not-allowed]="reportingLocationOnly && !bu.reportingLocation"
          (click)="emitSelected(bu)">
          Select
        </button>
      </div>
      <reporter-business-unit-details *ngIf="bu.id === buDetails?.id"
                                      [showClientNumber]="systemClientSelected"
                                      [buDetails]="buDetails"
                                      [buReferenceNumberPrompt]="buReferenceNumberPrompt">
      </reporter-business-unit-details>
    </div>
  </div>

</div>
<ng-container *ngIf="selected?.id">
  <div class="filter px-2">
    <div class="search-box d-flex align-baseline w-100">
      <div *ngFor="let dot of [].constructor(levels.length + 1)"
           class="dot"></div>
      <div class="spacer"></div>
      <div class="label">Filter</div>
      <div class="ml-2 mr-4 col px-0">
        <form (ngSubmit)="refineSearch()"
              (reset)="refineSearch()">
          <div class="input-group">
            <input name="searchFieldValue"
                   placeholder="Enter text to refine results"
                   type="text"
                   class="form-control"
                   [(ngModel)]="searchFieldValue">
            <div class="input-group-append">
              <button class="clear-search-input"
                      type="reset"
                      [hidden]="!searchTerm"
                      (click)="searchFieldValue = undefined; ">
                <i class="fas fa-times-circle"></i>
              </button>
              <button type="submit"
                      class="btn btn-action py-0">
                <reporter-icon icon="CheckMarkWhite"></reporter-icon>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="children"
       infiniteScroll
       [scrollWindow]="false"
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="100"
       [infiniteScrollDisabled]="totalPages < pageNumber"
       (scrolled)="loadChildren()"
       [style]="{'height': 'calc(100% - ' + (levels.length * 2) + 'rem - 2.5rem) !important' }">
    <div *ngFor="let child of children;"
         class="list-group-item"
         [class.in-focus]="inFocus?.id === child.id">
      <div class="controls">
        <reporter-icon
          class="mr-2"
          [icon]="child.hasChildren ? 'Collapsed' : 'Leaf'"
          [cursorPointer]="child.hasChildren"
          (click)="selectChild(child)">
        </reporter-icon>
      </div>
      <div class="reporting-location cursor-pointer col"
           (click)="getBUDetails(child)"
           (mouseenter)="mouseOver(child)"
           (mouseleave)="unsetTimeOut(child)">
        <div class="d-flex align-middle justify-content-between">
          <div>
            <span *ngIf="systemClientSelected" style="font-weight: bold;">
              {{ '[' + child.gbClientNumber + ']' }}
            </span>
            {{ child.displayName }}
          </div>
          <button
            type="button"
            class="btn btn-action py-0"
            *ngIf="child.id === buDetails?.id && (!reportingLocationOnly || child.reportingLocation)"
            [class.cursor-not-allowed]="reportingLocationOnly && !child.reportingLocation"
            (click)="emitSelected(child)">
            Select
          </button>
        </div>
        <reporter-business-unit-details *ngIf="child.id === buDetails?.id"
                                        [showClientNumber]="systemClientSelected"
                                        [buDetails]="buDetails"
                                        [buReferenceNumberPrompt]="buReferenceNumberPrompt">
        </reporter-business-unit-details>
      </div>
    </div>
  </div>
</ng-container>
