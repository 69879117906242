import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { ConfigurationService } from 'app/shared/services/configuration.service';

@Directive({
  selector: '[reporterLoginImage]',
})
export class LoginImageDirective {
  protected elementClasses: string[] = [];

  getImageNumber(): number {
    const loginPageImagesCount = parseInt(this.config.byKey('login.page.images.count'), 10);
    const now: any = new Date();
    const start: any = new Date(now.getFullYear(), 0, 0);
    const diff = now - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    const modDay = dayOfYear % loginPageImagesCount;

    return modDay + 1;
  }

  constructor(private renderer: Renderer2, private hostElement: ElementRef, private config: ConfigurationService) {
    const todaysDateImage = this.getImageNumber();

    this.renderer.setStyle(
      this.hostElement.nativeElement,
      'background-image',
      `url(assets/images/gbgo-fc-background-image-${todaysDateImage}.png)`
    );
  }
}
