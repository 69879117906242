import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Enum, Incident, Questionnaire } from 'app/shared/models';
import { EnumerationsService } from "app/shared/services/enumerations.service";
import { IncidentService, QuestionnairesService, UserService } from "app/shared/services";
import { Router } from "@angular/router";

@Component ( {
  selector: 'reporter-clone-record-dialog',
  templateUrl: './clone-record-dialog.component.html',
  styleUrls: [ './clone-record-dialog.component.scss' ]
} )
export class CloneRecordDialogComponent implements OnInit {

  @Input () incidentToClone: Incident;
  @Input () show = false;
  @Output () cloneRecord = new EventEmitter<any> ();
  @Output () close = new EventEmitter<boolean> ();

  cloneLineOfBusiness: string;
  cloneQuestionnaireId: string;
  linesOfBusiness: Enum[] = [];
  questionnaires: Questionnaire[] = [];

  constructor (
    private enumService: EnumerationsService,
    private questionnairesService: QuestionnairesService,
    private userService: UserService,
    private incidentService: IncidentService,
    private router: Router,
  ) {
  }

  ngOnInit (): void {
    const currentUserLocationIds = this.userService.currentClientLocationIds;
    this.enumService.getLinesOfBusiness ().subscribe ( ( linesOfBusiness: Enum[] ) => {
      this.linesOfBusiness = linesOfBusiness;
    } );
    this.questionnairesService.find ( '', currentUserLocationIds ).subscribe ( ( questionnaires: Questionnaire[] ) => {
      this.questionnaires = questionnaires;
    } );
  }

  cancel () {
    this.close.emit ();
  }

  clone (): void {
    this.incidentService
      .cloneIncident ( this.incidentToClone.id ?? this.incidentToClone.incidentId, this.cloneLineOfBusiness, this.cloneQuestionnaireId )
      .subscribe ( response => {
        this.router.navigate ( [ '/incident', response.id, 'wizard', 1 ] ).then ();
      } );
    this.close.emit (true);
  }
}
