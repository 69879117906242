<reporter-modal [displayCloseBtn]="true"
                [noFooter]="true"
                [show]="incidentId !== undefined"
                title="Printable Documents"
                modalClasses="modal-lg"
                (onClose)="onClose.emit()">

  <div role="modal-body"
       class="printable-documents">
    <reporter-notifications #notifications
                            [clearOnNew]="true"></reporter-notifications>
    <div class="content">
      <div *ngIf="documents?.length > 0"
           class="documents-table">
        <div class="documents-table-header"></div>
        <div class="documents-table-header">File Description</div>
        <ng-container *ngFor="let document of documents">
          <div class="documents-table-row"
               (click)="printDocument(document.formTemplateId)">
            <div class="documents-table-cell">
              <reporter-icon icon="Print"></reporter-icon>
            </div>
            <div class="documents-table-cell">
              <span>{{ document.name }}</span>
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="documents?.length === 0">
        There are no printable reports available for this Incident!
      </div>
      <div *ngIf="documents === undefined">
        Loading documents...
      </div>
    </div>
  </div>
</reporter-modal>

