<reporter-modal [show]="show"
                title="Clone Record"
                [noFooter]="false"
                [displayCloseBtn]="true"
                (onClose)="cancel()">
  <div role="modal-body">
    <div class="form-group">
      <label for="lineOfBusiness">Line of Business</label>
      <select
        [class.placeholder]="!cloneLineOfBusiness"
        class="form-control required"
        id="lineOfBusiness"
        [(ngModel)]="cloneLineOfBusiness">
        <option [ngValue]="undefined" selected>Select a Line of Business</option>
        <option *ngFor="let lineOfBusiness of linesOfBusiness"
                [ngValue]="lineOfBusiness.key">{{lineOfBusiness.displayValue}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="cloneQuestionnaireId">Questionnaire</label>
      <select
        [class.placeholder]="!cloneQuestionnaireId"
        class="form-control required" id="cloneQuestionnaireId"
        [(ngModel)]="cloneQuestionnaireId">
        <option [ngValue]="undefined" selected>Select a Questionnaire</option>
        <option *ngFor="let questionnaire of questionnaires"
                [ngValue]="questionnaire.id">{{questionnaire.displayName}}</option>
      </select>
    </div>
  </div>

  <div role="modal-footer" class="d-flex justify-content-center w-100">
    <button type="button"
            class="btn btn-action"
            (click)="clone()"
            [disabled]="!cloneQuestionnaireId || !cloneLineOfBusiness">
      Clone
    </button>
    <button type="button"
            class="btn btn-action ml-3"
            (click)="cancel()">
      Cancel
    </button>
  </div>
</reporter-modal>
