<select #field
        class="form-control"
        [ngModel]="innerValue"
        (ngModelChange)="writeValue($event)"
        (blur)="onTouched()">
  <option *ngIf="!required || !innerValue"
          [ngValue]="undefined"></option>
  <option *ngFor="let option of orderedOptions"
          [ngValue]="option.code">{{ option.displayValue || option.description }}</option>
</select>
