import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  Renderer2,
  ViewChild
} from '@angular/core';

import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component ( {
  selector: 'reporter-code',
  templateUrl: './code.component.html',
  styleUrls: [ './code.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( () => CodeComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( () => CodeComponent ),
      multi: true
    },
  ]
} )
export class CodeComponent implements ControlValueAccessor, Validator, AfterViewInit {

  @Input () options = [];
  @Input () isMalformed = false;

  constructor( private renderer: Renderer2, private elementRef: ElementRef ) {
  }

  set value( v: any ) {
    if ( v !== this.innerValue ) {
      this.innerValue = v;
      this.onChange ( v );
    }
  }

  get value() {
    return this.innerValue;
  }

  get required(): boolean {
    return this.elementRef.nativeElement.attributes.hasOwnProperty ( 'required' );
  }

  innerValue: any;
  initialValue: any;
  initialized = false;

  @ViewChild ( 'field', { static: true } ) field: ElementRef;
  onChange = ( _: any ) => {
  };
  onTouched = () => {
  };

  ngAfterViewInit() {
    this.initialValue = this.value;
    if ( this.required ) {
      this.renderer.setAttribute ( this.field.nativeElement, 'required', '' );
    }
    if ( this.elementRef.nativeElement.classList.contains ( 'required' ) ) {
      this.renderer.addClass ( this.field.nativeElement, 'required' );
    }
    this.initialized = true;
  }

  writeValue( value: any ): void {
    this.innerValue = value;
    this.renderer.setProperty ( this.elementRef.nativeElement, 'value', value );
    this.onChange ( value );
  }

  registerOnChange( fn: ( value: any ) => any ): void {
    this.onChange = fn;
  }

  registerOnTouched( fn: () => any ): void {
    this.onTouched = fn;
  }

  setDisabledState( isDisabled: boolean ): void {
    this.renderer.setProperty ( this.elementRef.nativeElement, 'disabled', isDisabled );
  }

  validate( control: FormControl ): ValidationErrors {
    if ( !this.initialized ) {
      return null;
    }

    if ( !control.value && this.required ) {
      return {
        required: true,
        message: ' is required.'
      };
    }

    if ( control.value && control.value !== this.initialValue ) {
      const validValue = this.options.filter ( o => o.code === control.value );
      if ( !validValue ) {
        return {
          pattern: true,
          message: ' does not match any of the provided valid options.'
        };
      }
    }

    return null;
  }

  get orderedOptions() {
    if ( this.displayIndexPresent( this.options ) ) {
      return this.sortByDisplayIndex( this.options );
    }
    return this.options?.sort( ( a, b ) => {
      return ( a.displayValue || a.description )?.toUpperCase() > ( b.displayValue || b.description )?.toUpperCase() ? 1 : -1;
    } );
  }

  private displayIndexPresent( codes ) {
    for ( let i = 0 ; i < codes?.length ; i++ ) {
      if ( codes[ i ].displayIndex != null ) {
        return true;
      }
    }
    return false;
  }

  private sortByDisplayIndex( codes ) {
    return codes.sort( ( n1, n2 ) => {
      if ( n1.displayIndex > n2.displayIndex ) {
        return 1;
      }
      if ( n1.displayIndex < n2.displayIndex ) {
        return -1;
      }
      return 0;
    } );
  }
}
