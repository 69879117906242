<reporter-modal [show]="show"
                [displayCloseBtn]="true"
                [noFooter]="true"
                [title]="(contactType === 'MEDICAL' ? 'Medical' : 'Employee') + ' Contact Lookup'"
                modalClasses="modal-lg"
                (onClose)="close.emit(true)">
  <div role="modal-body">
    <div class="content">
      <reporter-notifications [clearOnNew]="true"></reporter-notifications>
      <div class="contacts-search"
           [ngClass]="{'overflow-hidden': !contactsSearchOpen}">
        <div class="contact-search-control"
             (click)="contactsSearchOpen = !contactsSearchOpen">
          <div>
            <reporter-icon *ngIf="contactsSearchOpen"
                           icon="Expanded"></reporter-icon>
            <reporter-icon *ngIf="!contactsSearchOpen"
                           icon="Collapsed"></reporter-icon>
            <span class="pl-3">Contacts Search Criteria</span>
          </div>
        </div>
        <form role="form"
              #form="ngForm"
              class="px-2">
          <div class="search-form row mx-0 py-2"
               *ngIf="contactsSearchOpen">
            <div class="form-group col-12 col-md-6">
              <label>First Name</label>
              <input type="text"
                     name="firstName"
                     placeholder="First Name"
                     [(ngModel)]="searchModel['firstName']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Last Name</label>
              <input type="text"
                     name="lastName"
                     placeholder="Last Name"
                     [(ngModel)]="searchModel['lastName']"
                     class="form-control"/>
            </div>
            <div *ngIf="contactType === 'EMPLOYEE'" class="form-group col-12 col-md-6">
              <label>Employee Number</label>
              <input type="text"
                     name="employeeNumber"
                     placeholder="Employee Number"
                     [(ngModel)]="searchModel['employeeNumber']"
                     class="form-control"/>
            </div>
            <div *ngIf="contactType === 'EMPLOYEE'" class="form-group col-12 col-md-6">
              <label>SSN</label>
              <input type="text"
                     name="ssn"
                     placeholder="SSN"
                     [(ngModel)]="searchModel['ssn']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Company Name</label>
              <input type="text"
                     name="companyName"
                     placeholder="Company Name"
                     [(ngModel)]="searchModel['companyName']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>City</label>
              <input type="text"
                     name="city"
                     placeholder="City"
                     [(ngModel)]="searchModel['city']"
                     class="form-control"/>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>State</label>
              <select
                class="form-control"
                name="jurisdiction"
                [class.placeholder]="!searchModel['state']"
                [(ngModel)]="searchModel['state']">
                <option [ngValue]="undefined">State</option>
                <option *ngFor="let jurisdiction of jurisdictions; let index = index;"
                        [ngValue]="jurisdiction.key">{{ jurisdiction.displayValue }}</option>
              </select>
            </div>
            <div class="form-group col-12 col-md-6">
              <label>Postal Code</label>
              <input type="text"
                     class="form-control"
                     name="postalCode"
                     placeholder="Postal Code"
                     [(ngModel)]="searchModel['zip']">
            </div>

            <div class="d-flex col-12 justify-content-end align-items-center py-2 py-lg-0 mt-5">
              <button type="button"
                      class="btn btn-action"
                      data-test-id="search-form-clear"
                      (click)="resetForm()">CLEAR
              </button>
              <button type="submit"
                      class="btn btn-action ml-3"
                      (click)="search()">SEARCH
              </button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="contacts"
           class="results-count">{{ totalRecords }} record{{ totalRecords !== 1 ? 's' : '' }} found
      </div>

      <div class="search-results"
           *ngIf="contacts?.length"
           infiniteScroll
           [scrollWindow]="false"
           [infiniteScrollDistance]="1"
           [infiniteScrollThrottle]="50"
           [infiniteScrollDisabled]="pageNumber >= totalPages"
           (scrolled)="loadNextPage()">
        <div class="results-list">
          <div *ngFor="let contact of contacts; let index = index;"
               class="list-group-item"
               [class.zebra]="index % 2 == 1">
            <div class="reporting-location cursor-pointer col"
                 (click)="getDetails(contact)"
                 (mouseenter)="mouseOver(contact)"
                 (mouseleave)="unsetTimeOut(contact)">
              <div class="d-flex align-middle justify-content-between">
                <div>Name: {{ contact.firstName }} {{ contact.lastName }} {{ contact.companyName }}</div>
                <button
                  class="btn btn-action py-0"
                  *ngIf="contact.id === details?.id"
                  (click)="select(contact)">
                  Select
                </button>
              </div>
              <reporter-contact-details *ngIf="contact.id === details?.id"
                                        [contact]="details"></reporter-contact-details>
            </div>
          </div>
        </div>
      </div>

      <div class="results">

      </div>
    </div>
  </div>
</reporter-modal>
