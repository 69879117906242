import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {
  NgbDate,
  NgbInputDatepicker
} from '@ng-bootstrap/ng-bootstrap';
import { Policy } from 'app/shared/models';

@Component ( {
  selector: 'reporter-policy',
  templateUrl: './policy.component.html',
  styleUrls: [ './policy.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef ( () => PolicyComponent ),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef ( () => PolicyComponent ),
      multi: true
    }
  ]
} )
export class PolicyComponent implements ControlValueAccessor, Validator, AfterViewInit, OnChanges {

  @Input () searchDisabled = false;
  @Input () policies: Policy[] = [];
  @Input () jurisdiction: string;

  constructor( private renderer: Renderer2, private elementRef: ElementRef ) {
  }

  set value( v: any ) {
    if ( v !== this.innerValue ) {
      this.innerValue = v;
      this.onChange ( v );
    }
  }

  get value() {
    return this.innerValue;
  }

  get required(): boolean {
    return this.elementRef.nativeElement.attributes.hasOwnProperty ( 'required' );
  }

  innerValue: string;
  initialized = false;
  initialValue: any;

  selectedPolicy: Policy;
  showList = false;

  @ViewChild ( 'field', { static: true } ) field: ElementRef;
  @ViewChild ( 'button', { static: true } ) button: ElementRef;

  onChange = ( _: any ) => {
  };
  onTouched = () => {
  };

  ngOnChanges( changes: SimpleChanges ) {
    this.writeValue ( undefined );
    if ( changes.policies?.currentValue?.length === 1 ) {
      this.writeValue ( changes.policies?.currentValue[ 0 ] );
    }
    setTimeout ( _ => {
      this.setRequiredState ();
    }, 0 );
  }

  ngAfterViewInit() {
    this.initialValue = this.value;
    this.setRequiredState ();
    this.initialized = true;
  }

  setRequiredState() {
    if ( this.required ) {
      this.renderer.setAttribute ( this.field.nativeElement, 'required', '' );
    } else {
      this.renderer.removeAttribute ( this.field.nativeElement, 'required' );
    }
    if ( this.elementRef.nativeElement.classList.contains ( 'required' ) ) {
      this.renderer.addClass ( this.field.nativeElement, 'required' );
    }
  }

  writeValue( value: Policy ): void {
    this.selectedPolicy = value;
    this.innerValue = value?.id;
    this.renderer.setProperty ( this.elementRef.nativeElement, 'value', this.innerValue );
    this.onChange ( this.innerValue );
    if ( value ) {
      this.onTouched ();
    }
  }

  registerOnChange( fn: ( value: any ) => any ): void {
    this.onChange = fn;
  }

  registerOnTouched( fn: () => any ): void {
    this.onTouched = fn;
  }

  setDisabledState( isDisabled: boolean ): void {
    this.renderer.setProperty ( this.elementRef.nativeElement, 'disabled', isDisabled );
  }

  validate( control: FormControl ): ValidationErrors {
    if ( !this.initialized ) {
      return null;
    }

    if ( this.required && !control.value ) {
      return {
        required: true,
        message: ' is required.'
      };
    }
    return null;
  }

  showPolicies() {
    this.showList = true;
    this.onTouched ();
  }

  jurisdictionAdditionalInfo( policy: Policy ) {
    const jurisdiction = policy.jurisdictionList.find ( j => j.jurisdiction === this.jurisdiction );
    return jurisdiction?.additionalInfo || '';
  }

}
