import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[reporterHorizontalScroll]'
})
export class HorizontalScrollDirective {

  constructor(private el: ElementRef) { }

  @HostListener('wheel', ['$event']) onMouseWheel(event: WheelEvent): void {
    this.mouseWheel(event);
  }

  private mouseWheel(event) {
    if(event.deltaY > 0) {
      this.el.nativeElement.scrollLeft -= 10;
    } else {
      this.el.nativeElement.scrollLeft += 10;
    }
  }
}
