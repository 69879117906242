<div style="padding-left: 0.5rem;">
  <ul>
    <li *ngIf="showClientNumber">Client Number: {{ buDetails?.gbClientNumber }}</li>
    <li>Name: {{ buDetails?.name }}</li>
    <li>{{ buReferenceNumberPrompt }}: {{ buDetails?.referenceNumber }}</li>
    <li></li>
    <li>Address: {{ buDetails?.address1 }}{{ buDetails?.address2 ? ', ' : '' }}{{ buDetails?.address2 }}</li>
    <li>City: {{ buDetails?.city }}</li>
    <li>State: {{ buDetails?.jurisdiction?.split('_')[0] }}</li>
    <li>Postal Code: {{ buDetails?.postalCode }}</li>
    <li></li>
    <li *ngIf="buDetails?.phone != undefined">Phone: {{ buDetails.phone }}</li>
    <li *ngIf="buDetails?.email != undefined">Email: {{ buDetails.email }}</li>
    <li></li>
  </ul>
</div>
