import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Enum } from 'app/shared/models/enum.model';
import { Constants } from 'app/shared/config/constants';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EnumerationsService {

  readonly url = Constants.enumerationsServiceUrl;

  constructor(private http: HttpClient) { }

  getEnumerations(enumType: string): Observable<Enum[]> {
    const url = `${this.url}/${enumType}`;
    return this.http.get<Enum[]>(url).pipe(map(response=>{ return response.sort((a,b)=> a.displayIndex - b.displayIndex) }));
  }

  getIncidentStatuses = () => this.getEnumerations('incident-statuses');
  getLinesOfBusiness = () => this.getEnumerations('lines-of-business');
}
