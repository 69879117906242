/**
 * @author atanas.kovachev
 * @email akovachev@objectsystems.com
 * @create date 2020-10-13 18:01:36
 * @modify date 2020-10-13 18:01:36
 * @desc Format Different types of error to a model that the UI understands
 */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Error, Violation } from 'app/shared/models/error-model.model';

@Injectable ( {
  providedIn: 'root',
} )
export class ErrorsFormatterUtilService {
  errorSource;
  showDevMessages = false;

  constructor ( private router: Router ) {
  }

  /**
   * @param  {HttpErrorResponse|any} errorSource
   */
  formatError ( errorSource: HttpErrorResponse | any ): Error {
    this.errorSource = errorSource;

    if ( errorSource instanceof HttpErrorResponse ) {
      return this.processHttpError ();
    } else if ( errorSource ) {
      return this.processAnyErrorToString ();
    }
  }

  private processAnyErrorToString (): Error {
    const stringifyErrorOfAnyType = this.errorSource.toString ();
    return this.responseModel (
      'error',
      1000,
      'Unknown Error Has Occured',
      stringifyErrorOfAnyType,
      stringifyErrorOfAnyType,
      []
    );
  }

  private processHttpError (): Error {
    const httpError: HttpErrorResponse = this.errorSource;

    if ( this.errorSource && ( this.errorSource.error && this.errorSource.error.customerMessage ) ) {
      let violations = [];
      if ( this.errorSource.error.violations && this.errorSource.error.violations.length ) {
        violations = [ ...this.errorSource.error.violations ];
      }

      return this.responseModel (
        'error',
        httpError.status,
        httpError.statusText,
        httpError.error.customerMessage,
        httpError.error.developerMessage,
        violations
      );
    } else {
      return this.responseModel (
        'error',
        httpError.status,
        httpError.statusText,
        httpError.error?.message || httpError.message,
        httpError.message,
        []
      );
    }
  }

  /**
   * @param  {string} type
   * @param  {number} status
   * @param  {string} statusText
   * @param  {string} clientMessage
   * @param  {string} devMessage
   * @param  {Violation[]} violations
   */
  private responseModel (
    type: string,
    status: number,
    statusText: string,
    clientMessage: string,
    devMessage: string,
    violations: Violation[]
  ) {
    return {
      type: type,
      status: status,
      statusText: statusText,
      clientMessage: clientMessage,
      devMessage: this.showDevMessages ? devMessage : null,
      violations: violations,
    };
  }

  public redirectOnResolverError ( error: any ): void {
    if ( error.status !== 401 ) {
      this.router.navigate ( [ '/error' ], { state: { error: this.formatError ( error ), fromUrl: this.router.url } } );
    }
  }
}
