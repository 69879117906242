<div class="input-group">
  <input type="text"
         class="form-control"
         [class.hidden-date-field]="masked && maskedValue"
         ngbDatepicker
         #field="ngbDatepicker"
         placeholder="mm/dd/yyyy"
         [maxDate]="futureOk ? undefined : maxDate"
         [ngModel]="innerValue"
         (ngModelChange)="writeValue($event)"
         (dateSelect)="writeValue($event)"
         (blur)="onTouched()">
  <input [hidden]="!masked || !maskedValue"
         type="text"
         class="form-control"
         [(ngModel)]="maskedValue"
         (focus)="maskedFocus($event)"
         (ngModelChange)="maskedChange($event)">
  <div class="input-group-append">
    <button #button
            class="btn btn-action"
            (click)="field.toggle()"
            type="button">
      <reporter-icon icon="Calendar"></reporter-icon>
    </button>
  </div>
</div>
