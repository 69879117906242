<div class="modal fade" [class.show]="show" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog {{ modalClasses }}" role="document">
    <div class="modal-content">
      <div class="modal-header" [hidden]="!title && !displayCloseBtn">
        <h5 class="modal-title text-dark text-center w-100">{{ title }}</h5>
        <button [hidden]="!displayCloseBtn" type="button" class="close" (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-content select="[role=modal-body]"></ng-content>
      </div>
      <div [hidden]="noFooter" class="modal-footer px-0">
        <ng-content select="[role=modal-footer]"></ng-content>
      </div>
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="show" ></div>
