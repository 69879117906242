<div class="d-flex flex-column advanced-search"
     [ngClass]="{'overflow-hidden': !advancedSearchOpen}">
  <div class="advanced-search-control"
       (click)="advancedSearchOpen = !advancedSearchOpen">
    <div>
      <reporter-icon *ngIf="advancedSearchOpen"
                     icon="Expanded"></reporter-icon>
      <reporter-icon *ngIf="!advancedSearchOpen"
                     icon="Collapsed"></reporter-icon>
      <span class="pl-3">BU Search Criteria</span>
    </div>
  </div>
  <form role="form"
        #form="ngForm"
        class="px-2">
    <div *ngIf="advancedSearchOpen"
         class="advanced-search-form row mx-0 py-2">
      <div class="form-group col-12 col-md-6">
        <label>Name</label>
        <input type="text"
               name="name"
               placeholder="Name"
               [(ngModel)]="searchModel['name']"
               class="form-control"/>
      </div>
      <div class="form-group col-12 col-md-6">
        <label>{{ buReferenceNumberPrompt }}</label>
        <input type="text"
               name="referenceNumber"
               [placeholder]="buReferenceNumberPrompt"
               [(ngModel)]="searchModel['referenceNumber']"
               class="form-control"/>
      </div>
      <div class="form-group col-12 col-md-6">
        <label>City</label>
        <input type="text"
               name="city"
               placeholder="City"
               [(ngModel)]="searchModel['city']"
               class="form-control"/>
      </div>
      <div class="form-group col-12 col-md-6">
        <label>State</label>
        <select
          class="form-control"
          name="jurisdiction"
          [class.placeholder]="!searchModel['jurisdiction']"
          [(ngModel)]="searchModel['jurisdiction']">
          <option [ngValue]="undefined">State</option>
          <option *ngFor="let jurisdiction of jurisdictions; let index = index;"
                  [ngValue]="jurisdiction.key">{{ jurisdiction.displayValue }}</option>
        </select>
      </div>
      <div class="form-group col-12 col-md-6">
        <label>Postal Code</label>
        <input type="text"
               class="form-control"
               name="postalCode"
               placeholder="Postal Code"
               [(ngModel)]="searchModel['postalCode']">
      </div>
      <div class="form-group col-12 col-md-6">
        <label>Phone</label>
        <reporter-phone
          name="phone"
          placeholder="Phone"
          [(ngModel)]="searchModel['phone']"></reporter-phone>
      </div>
      <div class="form-group col-12 col-md-6">
        <div class="custom-control custom-checkbox">
          <input type="checkbox"
                 class="custom-control-input"
                 id="reportingLocationsOnly"
                 [checked]="searchModel['reportingLocationsOnly'] === true"
                 (change)="searchModel['reportingLocationsOnly'] = !searchModel['reportingLocationsOnly']"
                 [disabled]="reportingLocationsOnly"/>
          <label class="custom-control-label"
                 for="reportingLocationsOnly">Reporting Locations Only</label>
        </div>
      </div>
      <div class="d-flex col-12 justify-content-end align-items-center py-2 py-lg-0">
        <button type="button"
                class="btn btn-action"
                data-test-id="search-form-clear"
                (click)="resetForm()">CLEAR
        </button>
        <button type="submit"
                class="btn btn-action ml-3"
                (click)="submit()">SEARCH
        </button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="searchResults"
     class="results-count">{{ totalRecords }} record{{ totalRecords !== 1 ? 's' : '' }} found
</div>
<div class="search-results"
     *ngIf="searchResults?.length"
     infiniteScroll
     [scrollWindow]="false"
     [infiniteScrollDistance]="1"
     [infiniteScrollThrottle]="50"
     [infiniteScrollDisabled]="pageNumber >= totalPages"
     (scrolled)="loadNextPage()">
  <div class="results-list">
    <div *ngFor="let bu of searchResults; let index = index;"
         class="list-group-item"
         [class.zebra]="index % 2 == 1">
      <div class="reporting-location cursor-pointer col"
           (click)="getBUDetails(bu)"
           (mouseenter)="mouseOver(bu)"
           (mouseleave)="unsetTimeOut(bu)">
        <div class="d-flex align-middle justify-content-between">
          <div>
            <span *ngIf="systemClientSelected" style="font-weight: bold;">
              {{ '[' + bu.gbClientNumber + ']' }}
            </span>
            {{ bu.displayName }}
          </div>
          <button
            type="button"
            class="btn btn-action py-0"
            *ngIf="bu.id === buDetails?.id && (!reportingLocationsOnly || bu.reportingLocation)"
            [class.cursor-not-allowed]="reportingLocationsOnly && !bu.reportingLocation"
            (click)="emitSelected(bu)">
            Select
          </button>
        </div>
        <reporter-business-unit-details *ngIf="bu.id === buDetails?.id"
                                        [showClientNumber]="systemClientSelected"
                                        [buDetails]="buDetails"
                                        [buReferenceNumberPrompt]="buReferenceNumberPrompt">
        </reporter-business-unit-details>
      </div>
    </div>
  </div>
</div>
