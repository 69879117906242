<div *ngIf="show">
  <div class="modal-header">
    <h5 class="text-danger">{{ title }}</h5>
    <button type="button"
            class="close"
            aria-label="Close"
            (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <strong>{{ message }}</strong>
    <div data-id="violations-list"
         *ngIf="violations && violations.length" class="more-details-form">
      <div class="header">
        <div (click)="isCollapsed = !isCollapsed">
               <reporter-icon *ngIf="!isCollapsed"
                              icon="Expanded"></reporter-icon>
               <reporter-icon *ngIf="isCollapsed"
                              icon="Collapsed"></reporter-icon>
               <span class="pl-2">More details</span>
             </div>
      </div>
      <div id="collapse"
           [ngbCollapse]="isCollapsed">
        <div class="card">
          <ul class="bullet">
            <li  class="card-body" *ngFor="let violation of violations">{{ violation.message }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
