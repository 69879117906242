import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Renderer2,
  ViewChild
} from '@angular/core';

import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from '@angular/forms';

@Component({
  selector: 'reporter-three-state',
  templateUrl: './three-state.component.html',
  styleUrls: [ './three-state.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ThreeStateComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ThreeStateComponent),
      multi: true
    },
  ]
})
export class ThreeStateComponent implements ControlValueAccessor, Validator, AfterViewInit {

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChange(v);
    }
  }

  get value() {
    return this.innerValue;
  }

  get required(): boolean {
    return this.elementRef.nativeElement.attributes.hasOwnProperty('required');
  }

  innerValue: any;
  initialized = false;
  initialValue: any;

  @ViewChild('field', { static: true }) field: ElementRef;
  onChange = (_: any) => {};
  onTouched = () => {};

  ngAfterViewInit() {
    this.initialValue = this.value;
    if (this.required) {
      this.renderer.setAttribute(this.field.nativeElement, 'required', '');
    }
    if (this.elementRef.nativeElement.classList.contains('required')) {
      this.renderer.addClass(this.field.nativeElement, 'required');
    }
    this.initialized = true;
  }

  writeValue(value: any): void {
    this.innerValue = value;
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
    this.onChange(value);
  }

  registerOnChange(fn: (value: any) => any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }

  validate(control: FormControl): ValidationErrors {
    if (!this.initialized) {
      return null;
    }
    if (!control.value && this.required) {
      return {
        required: true,
        message: ' is required.'
      };
    }
    if (control.value && this.initialValue !== control.value && [
      'Y',
      'N',
      'U'
    ].indexOf(control.value) === -1) {
      return {
        pattern: true,
        message: ' must be Yes or No.'
      };
    }
    return null;
  }
}
