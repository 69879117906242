<div>
  <ul>
    <li>Plate Number: {{ vehicle?.plateNumber }}</li>
    <li>Vehicle #/ID: {{ vehicle?.number }}</li>
    <li>Make: {{ vehicle?.make }}</li>
    <li>Model: {{ vehicle?.model }}</li>
    <li>Year: {{ vehicle?.year }}</li>
    <li></li>
  </ul>
</div>
