<input #field
       type="number"
       class="form-control"
       [ngModel]="innerValue"
       [value]="innerValue"
       (ngModelChange)="writeValue($event)"
       step="0.1"
       min="-999999999.9"
       max="999999999.9"
       (blur)="onTouched()">
