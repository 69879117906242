import {
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'reporter-icon',
  templateUrl: './icon.component.html',
  styleUrls: [ './icon.component.scss' ],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent {

  @Input() size = 18;
  @Input() icon = '';
  @Input() cursorPointer = false;
  @Input() tooltip = null;

  constructor() { }
}
