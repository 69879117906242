import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { IncidentService } from 'app/shared/services';
import { AvailableDocument } from 'app/shared/models';
import { take } from 'rxjs/operators';
import { NotificationsComponent } from 'app/shared/components';

@Component ( {
  selector: 'reporter-printable-documents',
  templateUrl: './printable-documents.component.html',
  styleUrls: [ './printable-documents.component.scss' ]
} )
export class PrintableDocumentsComponent implements OnInit, OnChanges {

  @Input () incidentId: string;
  @Output () onClose = new EventEmitter<any> ();

  @ViewChild ( 'notifications', { static: true } ) notificationsComponent: NotificationsComponent;

  documents: AvailableDocument[];

  constructor( private incidentService: IncidentService ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges( changes: SimpleChanges ) {
    if ( changes.incidentId?.currentValue ) {
      this.getPrintableDocuments ();
    }
  }

  getPrintableDocuments() {
    this.notificationsComponent.clear ();
    this.documents = undefined;
    this.incidentService.getPrintableDocuments ( this.incidentId )
      .pipe ( take ( 1 ) )
      .subscribe ( documents => this.documents = documents );
  }

  printDocument( formTemplateId: string ) {
    this.incidentService.printDocument ( this.incidentId, formTemplateId )
      .pipe ( take ( 1 ) )
      .subscribe ( res => {
        const keys = res.headers.keys ();
        const headers = keys.map ( key =>
          `${ key }: ${ res.headers.get ( key ) }` );
        const filename = res.headers.get ( 'content-disposition' )
          .split ( ';' )
          .find ( s => s.match ( 'filename' ) )
          ?.split ( '=' )[ 1 ].replace ( /\"/g, '' ) || 'form.pdf';
        const url = window.URL.createObjectURL ( res.body );
        const a = document.createElement ( 'a' );
        document.body.appendChild ( a );
        a.download = filename;
        a.rel = 'noopener';
        a.href = url;
        a.click ();
        setTimeout ( () => {
          window.URL.revokeObjectURL ( url );
          document.body.removeChild ( a );
        }, 0 );
      } );
  }

}
