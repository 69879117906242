<input #field
       type="hidden"
       [ngModel]="innerValue"
       (ngModelChange)="writeValue($event)">
<div class="input-group">
  <input type="text"
         class="form-control"
         [(ngModel)]="typeInValue"
         [required]="required"
         (ngModelChange)="writeValue($event)"
         [readOnly]="readOnly || selected">
  <div class="input-group-append">
    <button *ngIf="innerValue || typeInValue"
            type="button"
            class="clear-search"
            (click)="clear()">
      <i class="fas fa-times-circle"></i>
    </button>
    <button *ngIf="!innerValue"
            class="btn btn-action"
            (click)="showSearch = true"
            type="button">
      <reporter-icon icon="Search"></reporter-icon>
    </button>
  </div>
</div>

<reporter-contacts-search *ngIf="showSearch"
                          [show]="showSearch"
                          (close)="close()"
                          (selectContact)="select($event)"
                          [contactType]="contactType">
</reporter-contacts-search>

