<input #field
       type="hidden"
       [ngModel]="innerValue"
       (ngModelChange)="writeValue($event)">
<div class="input-group">
  <input type="text"
         class="form-control"
         [(ngModel)]="searchTerm"
         [disabled]="innerValue"
         *ngIf="!innerValue"
         [required]="required">
  <input *ngIf="innerValue"
         type="text"
         class="form-control"
         [ngModel]="selected?.name"
         disabled
         [required]="required">
  <div class="input-group-append">
    <button *ngIf="innerValue"
            type="button"
            class="clear-search"
            (click)="clear()">
      <i class="fas fa-times-circle"></i>
    </button>
    <button *ngIf="!innerValue"
            class="btn btn-action"
            (click)="search()"
            type="button"
            [disabled]="!searchTerm">
      <reporter-icon icon="Search"></reporter-icon>
    </button>
  </div>
  <div class="dropdown-menu w-100"
       [class.show]="branches?.length > 1 && !innerValue">
    <button *ngFor="let branch of branches; let index = index"
            class="dropdown-item"
            type="button"
            (click)="select(branch)">{{ branch.name }}
    </button>
  </div>
  <div *ngIf="branches?.length === 0" class="w-100 alert alert-warning">
    Branch Not Found!
  </div>
</div>

