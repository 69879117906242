import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomChangeDirective } from './dom-change/dom-change.directive';
import { LoginImageDirective } from './login-image/login-image.directive';
import { HorizontalScrollDirective } from './horizontal-scroll/horizontal-scroll.directive';
import { DateFormatDirective } from "app/shared/directives/date-format/date-format.directive";

@NgModule({
  declarations: [
    DomChangeDirective,
    LoginImageDirective,
    HorizontalScrollDirective,
    DateFormatDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DomChangeDirective,
    LoginImageDirective,
    HorizontalScrollDirective,
    DateFormatDirective,
  ]
})
export class DirectivesModule {}
