<select #field
        class="form-control"
        [ngModel]="innerValue"
        (ngModelChange)="writeValue($event)"
        (blur)="onTouched()">
  <option [ngValue]="undefined"></option>
  <option ngValue="Y">Yes</option>
  <option ngValue="N">No</option>
  <option ngValue="U">Unknown</option>
</select>
