import {
  Component,
  Input,
  OnInit
} from '@angular/core';

@Component({
  selector: 'reporter-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
export class InstructionComponent implements OnInit {

  @Input() text: string;

  constructor() { }

  ngOnInit(): void {
  }

}
