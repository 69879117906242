<ng-container *ngIf="!maskWage || !isWage || innerValue !== maskedWageValue">
  <input #field
         type="number"
         class="form-control"
         [class.required]="required"
         [ngModel]="innerValue"
         [value]="innerValue"
         (ngModelChange)="writeValue($event)"
         (blur)="onTouched()"
         min="-99999999.99"
         max="99999999.99"
         step="0.01"
         [attr.required]="required ? '' : null">
</ng-container>

<ng-container *ngIf="maskWage && isWage && innerValue === maskedWageValue">
  <input #fieldMasked
         type="text"
         class="form-control"
         [class.required]="required"
         [ngModel]="innerValue"
         [value]="innerValue"
         (ngModelChange)="writeValue($event)"
         (blur)="onTouched()"
         [attr.required]="required ? '' : null">
</ng-container>
