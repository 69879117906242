<div class="d-flex align-items-end flex-wrap">
  <div class="d-flex align-items-end flex-grow-1">
    <div class="form-group m-0 type-ahead-field">
      <label>{{ label }}</label>
      <input type="text"
             [ngbTypeahead]="search"
             [inputFormatter]="formatter"
             [ngModel]="selectedItem"
             [resultFormatter]="formatter"
             (selectItem)="selectItem($event)"
             class="form-control"
             placeholder="Business unit"
             [required]="required"
             [disabled]="selectedItem !== undefined">
      <button class="clear-search"
              type="button"
              *ngIf="selectedItem"
              (click)="clear()">
        <i class="fas fa-times-circle"></i>
      </button>
    </div>
    <button type="button"
            class="btn btn-action ml-2 search-button py-0"
            (click)="openTreeView()">
      <reporter-icon [icon]="selectedItem ? 'ViewFacility' : 'Search'"></reporter-icon>
    </button>
  </div>
  <div *ngIf="showSubUnitsChecked"
       class="custom-checkbox">
    <input type="checkbox"
           class="custom-control-input"
           [(ngModel)]="subUnitsChecked"
           (change)="emitLocalData()"
           id="includeSubUnits"/>
    <label class="custom-control-label"
           for="includeSubUnits">Include Sub-Units</label>
  </div>
</div>
